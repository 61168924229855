import React from "react";
import { graphql } from "gatsby";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { EditableMarkdown, SponsorsSection } from "@app/components";

export interface ContactPageData {
  title: any;
  body: any;
}

export const ContactPage = ({
  data,
  isEdit,
}: {
  data: ContactPageData;
  isEdit: boolean;
}) => {
  if (!isEdit) {
    data = {
      ...(data as any).content.childMarkdownRemark.frontmatter,
      body: (data as any).content.childMarkdownRemark.html,
    };
  }

  return (
    <>
      <section className="py-5">
        <Container>
          <Row className="justify-content-center">
            {/* intro */}
            <Col md={8}>
              <h1 className="text-primary">{data.title}</h1>
              <EditableMarkdown isEdit={isEdit} markdown={data.body} />
            </Col>

            {/* form */}
            <Col md={8}>
              <Form
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="contact"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />

                {/* first name */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    First name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control size="lg" placeholder="E.g. John" required />
                </Form.Group>

                {/* last name */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    Last name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control size="lg" placeholder="E.g. Doe" required />
                </Form.Group>

                {/* email */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="email"
                    placeholder="E.g. john@doe.com"
                    required
                  />
                </Form.Group>

                {/* subject */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    Subject<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control size="lg" required />
                </Form.Group>

                {/* message */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    Message<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control size="lg" as="textarea" rows={3} required />
                </Form.Group>

                <Button variant="secondary" size="lg" type="submit">
                  SUBMIT
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>

      {/* sponsors */}
      {!isEdit && <SponsorsSection />}
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query {
    content: file(
      sourceInstanceName: { eq: "content" }
      relativeDirectory: { eq: "pages" }
      name: { eq: "contact" }
    ) {
      childMarkdownRemark {
        html
        frontmatter {
          seo_title
          seo_description
          title
        }
      }
    }
  }
`;
